import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { from } from "rxjs";
import { delay, mergeMap, retryWhen } from "rxjs/operators";
import { environment } from "src/environments/environment";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class ApiHelperService {
  constructor(private afAuth: AngularFireAuth, private http: HttpClient) {}

  // --- post with retry mechanism in below conditions
  // --- 1. if response status is 504 (Gateway Timeout)
  postWithRetry(url: string, payload: any, headers: any) {
    return from(
      this.http.post(url, { data: payload }, { headers, observe: "response" })
    )
      .pipe(
        mergeMap(async (response: any) => {
          if (response && response.status === 504) {
            throw new Error("Gateway Timeout");
          } else {
            return response.body;
          }
        }),
        retryWhen(errors => {
          let retries = 0;
          const maxRetries = 3;
          return errors.pipe(
            mergeMap(error => {
              const shouldRetry = async () => {
                if (!error) return false;
                if (retries >= maxRetries) return false;

                // --- retry in case of gateway timeout
                // --- retry in case of network interruptions (status == 0)
                if (
                  error.message === "Gateway Timeout" ||
                  error.status === 504 ||
                  error.status === 0
                )
                  return true;

                // --- in case of error codes 1007/1009, retry after refreshing token
                let errMsg = lodash.get(error, "error.error.message");
                if (
                  errMsg &&
                  (lodash.includes(errMsg, "1007") ||
                    lodash.includes(errMsg, "1009"))
                ) {
                  let user = await this.afAuth.currentUser;
                  if (user) {
                    await user.getIdToken(true);
                    return true;
                  }
                }

                return false;
              };

              return from(
                new Promise(async (resolve, reject) => {
                  let retry = await shouldRetry();
                  if (!retry) return reject(error);

                  retries++;
                  return resolve(null);
                })
              );
            }),
            delay(300)
          );
        })
      )
      .toPromise();
  }

  // --- prepare callable function URL
  prepareCallableFunURL(funName: string) {
    return environment.firebaseCallableFunctionUrl + funName;
  }

  // --- helper function to make cloud function API call
  async postToCloudFn(fnName: string, payload: any, overrideToken?: string) {
    // --- get current user auth token
    let token;
    if (overrideToken) token = overrideToken;
    else {
      let currentUser = await this.afAuth.currentUser;
      if(currentUser) {
        token = await currentUser.getIdToken();
      }
    }

    // --- prepare headers
    let headers = {
      Authorization: `Bearer ${token}`
    };

    // --- prepare server URL to send request to
    let url = this.prepareCallableFunURL(fnName);

    return this.postWithRetry(url, payload, headers);
  }
}

export enum CloudFnNames {
  getGenLoginIdentitySettings = "getgenloginidentitysettings",
  searchInds = "searchinds",
  setIndPW = "setindpw",
  getChallengesForInd = "getchallengesforind",
  verifyGenLoginQueAns = "verifygenloginqueans",
  getIndividualData = "getindividualdata",
  getOrgData = "getorgdata",
  getOrgMgrData = "getorgmgrdata",
  searchOrgs = "searchorgs",
  removeAnnUser = "removeannuser",
  resetIndPW = "resetindpw",
  addInd = "addind",
  updateInd = "updateind",
  sendMsgToInd = "sendmsgtoind",
  getIndEmail = "getindemail",
  publicOrgSignup = "publicorgsignup",
  searchStudios = "searchstudios",
  searchCCDOrgs = "searchccdorgs",
  getCustomToken = "getcustomtoken",
  verify2FA = "verifytwofa",
  reject2FA = "rejecttwofa",
  getIndMedia = "getindmedia",
  addIndMedia = "addindmedia",
  updateIndMedia = "updateindmedia",
  addRelation = "addrelation",
  getIdTokenUsingId = "getidtokenusingid",
  getOrgs = "getorgs",
  getShortenURL = "getshortenurl",
  bulkCreateUpdateVisitorInds = 'bulkcreateupdatevisitorinds',
  sendNotificationEmailSms = "sendnotificationemailsms",
  addPrintQueue = "addprintqueue"
}
